/* ==========================================================================
   #COMMENT
   ========================================================================== */

.c-comment {
  margin-bottom: 3rem;
}

.c-comment__moderation {
  font-style: italic;
  font-size: 1.4rem;
  color: var(--section-accent-color);
}

.c-comment__content {
  font-size: 1.4rem;
}
