/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: $color-bg;

  &.is-stuck {
    box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08),
      0 16px 16px rgba(50, 50, 71, 0.08);
  }
}

.c-header__sentinel {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}

.c-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.c-header__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  height: 4rem;
  width: 4rem;
  background: transparent;
  cursor: pointer;

  @include bp(medium) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 4rem;
    height: 0.2rem;
    background: $color-primary;
  }

  &--open {
    flex-direction: column;

    &::before,
    &::after {
      margin: 0.4rem 0;
    }
  }

  &--close {
    position: absolute;
    top: 3.6rem;
    right: 3rem;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 0;
      margin: -0.1rem 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.c-header__nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 21rem;
  padding: 11rem 3rem 3rem;
  background: $color-white;
  transform: translate(100%, 0);
  transform-origin: right center;
  transition: transform 300ms ease-in-out;

  @include bp(medium) {
    position: static;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: auto;
    max-width: none;
    padding: 0;
    background: transparent;
    transform: none;
    transition: none;
  }

  &.is-open {
    transform: translate(0, 0);
    box-shadow: 0 0 32px rgba(50, 50, 71, 0.08), 0 0 16px rgba(50, 50, 71, 0.08);

    @include bp(medium) {
      transform: none;
      box-shadow: none;
    }
  }
}

.c-header__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(medium) {
    flex-direction: row;
  }
}

.c-header__cta-item {
  margin: 1rem 0;

  @include bp(medium) {
    margin: 0 0 0 2rem;
  }
}

.c-header__nav-links {
  width: 100%;
  padding: 1.5rem 0 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @include bp(medium) {
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
  }
}

.c-header__nav-item,
.c-header__nav-subitem {
  position: relative;
  margin: 0;
}

.c-header__nav-link,
.c-header__nav-sublink {
  display: flex;
  align-items: center;
  color: $color-primary;
  font-weight: 500;
  text-decoration: none;
}

.c-header__nav-caret {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  width: 25px;
  height: 25px;

  @include bp(medium) {
    width: 15px;
    height: 15px;
  }

  span {
    display: block;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid $color-primary;
    transition: $global-transition;

    .c-header__nav-link:hover &,
    .c-header__nav-link:focus &,
    .current-menu-item & {
      border-top-color: $color-grey;
    }

    .c-header__nav-link:hover &,
    .c-header__nav-link:focus &,
    .c-header__nav-item.is-open & {
      transform: rotate(180deg);
    }
  }
}

.c-header__nav-link {
  padding: 1rem 0;
  justify-content: space-between;
  font-size: 1.6rem;
  transition: $global-transition;
  text-align: left;

  &:hover,
  &:focus,
  .current-menu-item > & {
    color: $color-grey;
    text-decoration: none;
  }

  @include bp(medium) {
    justify-content: flex-start;
    padding: 1rem 2.5rem;
  }

  @include bp(large) {
    padding: 1rem 3.5rem;
  }
}

.c-header__nav-dropdown {
  position: relative;
  display: none;
  transition: $global-transition;

  .c-header__nav-item.is-open & {
    display: block;
  }

  @include bp(medium) {
    display: block;
    position: absolute;
    min-width: 150px;
    top: 100%;
    left: 0;
    padding: 1rem;
    margin-top: 3rem;
    background-color: $color-white;
    box-shadow: 1px 1px 3px rgba($color-primary, 0.2);
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
    visibility: hidden;
    opacity: 0;

    .c-header__nav-item.is-open & {
      margin-top: 0;
      visibility: visible;
      opacity: 1;
    }
  }
}

.c-header__nav-sublink {
  font-size: 1.5rem;
  padding: 0.5rem;
  text-align: left;

  &:hover,
  &:focus,
  .current-menu-item > & {
    color: $color-grey;
    text-decoration: none;
  }
}
