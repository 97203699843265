.o-tag {
  margin-bottom: 3rem;
  color: $color-primary;
  font-size: 1.5rem;
  text-transform: uppercase;

  &--accent {
    color: var(--section-accent-color);
  }
}
