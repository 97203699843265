/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */

.c-comment-form {
  font-size: 1.4rem;
}

.comment-notes {
  margin-bottom: 4rem;
}

.comment-form-comment,
.comment-form-author,
.comment-form-email,
.comment-form-url {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 3rem;

  label {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 100%;
    font-size: 1rem;
    transition: transform 200ms ease-in-out;
  }

  input,
  textarea {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1.6rem 2rem;
    min-height: 5rem;
    background: #fff;
    border: 1px solid $color-border;
    border-radius: 5px;
    font-size: 1.3rem;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px $color-primary;
    }
  }

  textarea {
    min-height: 14rem;
  }

  &.is-empty label {
    transform: translate(2.8rem, 3.3rem) scale(1.3);
    pointer-events: none;
  }

  &.is-invalid {
    label {
      color: $color-error;
    }

    input,
    textarea {
      border-color: $color-error;
      box-shadow: 0 8px 8px rgba($color-error, 0.08);
    }
  }
}

.comment-form-cookies-consent {
  display: flex;
  align-items: flex-start;
  font-size: 1.3rem;
  margin-bottom: 3rem;

  label {
    margin: 0.2rem 0 0 1.2rem;
  }

  input {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.form-submit {
  display: flex;
  justify-content: center;
  padding-top: 4.5rem;
  margin: 4.5rem 0;
  border-top: 1px solid rgba(102, 102, 102, 0.24);
  text-align: center;

  input {
    @extend .o-btn;
    @extend .o-btn--primary;

    flex: 0 1 auto;
    width: 30rem;
  }
}
