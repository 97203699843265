.u-color--section-accent {
  color: var(--section-accent-color);
}

.u-color--headings {
  color: $color-blue-black;
}

.u-color--primary {
  color: $color-primary;
}
