.c-tease {
  max-width: 78rem;
  margin: 0 auto;
  padding: 3rem 0;
  border-bottom: 1px solid $color-transparent-grey;
}

.c-tease__thumbnail {
  display: block;
  margin-bottom: 3rem;
}

.c-tease__link {
  text-decoration: none;
}

.c-tease__heading {
  margin-bottom: 0.5rem;
}

.c-tease__meta {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}
