.c-post__author {
  max-width: 78rem;
  margin: 3rem auto 0;
}

.c-post__author-inner {
  display: block;

  @include bp(small) {
    display: flex;
  }
}

.c-post__author-avatar {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  margin-bottom: 2rem;

  @include bp(small) {
    margin-bottom: 0;
  }

  @include bp(medium) {
    width: 150px;
    height: 150px;
  }
}

.c-post__author-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-post-author__info {
  font-size: 1.6rem;

  @include bp(small) {
    padding-left: 1rem;
  }

  @include bp(medium) {
    padding-left: 2rem;
  }
}

.c-post-author__links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  li:not(:first-child) {
    ::before {
      content: '|';
      margin: 0 0.5rem;
    }
  }

  a {
    text-decoration: none;
    color: $color-grey;

    &:hover {
      color: $color-primary;
    }
  }
}
