.c-page__header {
  max-width: 48rem;
  margin: 0 auto 7.2rem;
  text-align: center;
  font-size: 1.8rem;
}

.c-page__title {
  &::after {
    content: '';
    display: block;
    width: 3.6rem;
    margin: 3.6rem auto;
    border-top: 2px solid $color-border;
  }
}

.c-page__excerpt {
  color: $color-grey;
}
