.o-text {
  @include clearfix();
  max-width: 66rem;

  &--center {
    text-align: center;
    margin: 0 auto;
  }

  &--bottom-space {
    margin-bottom: 9rem;
  }
}

.o-text__heading {
  .o-text--center & {
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.o-text__content {
  &--bottom-line {
    @include bottom-line(100%);
  }
}

.o-text__arrow.c-arrow-decor {
  display: inline-block;
  vertical-align: text-top;
  margin-bottom: 0;
  margin-left: 6rem;
}
