.c-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 78rem;
  margin: 0 auto;
  padding: 3rem 0;
  font-size: 1.8rem;
}

.c-pagination__pages {
  display: none;

  @include bp(medium) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.c-pagination__page {
  margin: 0 1rem;
}

.c-pagination__dir,
.c-pagination__link,
.c-pagination__plain {
  min-width: 4rem;
}

.c-pagination__plain,
.c-pagination__dir.o-btn--outline {
  cursor: default;

  &:hover {
    background-position: 0 0;
  }
}
