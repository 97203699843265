/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: $size-padding;
  padding-right: $size-padding;
  max-width: $size-wrapper + 2 * $size-padding;
}
