.c-faq {
  margin-top: 5rem;
}

.c-faq__details {
  display: block;
  padding: 0;
  margin-bottom: 2rem;
  border: 1px solid $color-transparent-grey;
  border-radius: $global-radius;
  background: $color-white;
  transition: box-shadow 500ms ease-in-out, margin 500ms ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &.is-open,
  .no-js &[open] {
    margin-bottom: 3.5rem;
    box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08),
      0 16px 16px rgba(50, 50, 71, 0.08);
  }
}

.c-faq__summary {
  display: block;
  position: relative;
  margin: 0;
  padding: 3.5rem 8rem 3.5rem 2rem;
  list-style: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-details-marker {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.c-faq__close {
  display: block;
  position: absolute;
  top: 3.2rem;
  right: 3.2rem;
  width: 2.2rem;
  height: 2.2rem;
  color: $color-primary;
  opacity: 0;
  transition: opacity 500ms ease-in-out;

  .c-faq__details.is-open &,
  .no-js .c-faq__details[open] & {
    opacity: 1;
  }
}

.c-faq__inner {
  --content-height: auto;

  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  height: var(--content-height);
  transition: height 500ms ease-in-out;
}

.c-faq__content {
  padding: 0 8rem 3.5rem 2rem;
  font-size: $font-size-smaller;
}
