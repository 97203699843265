.c-reasons {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  row-gap: 4rem;

  @include bp(medium) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    column-gap: 4rem;
  }

  @include bp(large) {
    margin-right: -6rem;
  }

  @include bp(xlarge) {
    grid-template-columns: 1fr auto;
    column-gap: 5rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &::before {
    width: 87rem;
    height: 87rem;
    background: rgba(74, 237, 194, 0.25);

    @include bp(medium) {
      width: 51.2rem;
      height: 51.2rem;
    }
  }

  &::after {
    width: 62rem;
    height: 62rem;
    background: rgba(46, 212, 122, 0.5);

    @include bp(medium) {
      width: 33.8rem;
      height: 33.8rem;
    }
  }
}

.c-reasons__item {
  position: relative;
  z-index: 1;
  max-width: 28rem;
  padding: 2rem 3rem 4.5rem;
  background: $color-white;
  border: 1px solid $color-transparent-grey;
  border-radius: 1rem;
  box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08),
    0 16px 16px rgba(50, 50, 71, 0.08);

  @include bp(medium) {
    grid-row-end: span 2;
    max-width: 29.5rem;
    min-height: 34rem;
  }

  &:first-child {
    border-color: $color-primary;
  }

  &::before {
    content: '';
    position: absolute;
    width: 8.1rem;
    height: 3.8rem;
    background: svg-url(
      '<svg width="81" height="38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.22 26.26c0-.9-.33-2.82.43-3.37 1.02-.74 1.41.69 1.7 1.6.51 1.79.28 4.78 1.21 6.24C9.76 18.08 19.24 8.61 31.02 3.74 42.74-1.1 57.11-2.04 68.14 5.72c5.4 3.79 9.9 9.3 11.6 16.57.13.63.96 3.67-.16 3.55-.58-.06-1.63-3.99-1.9-4.65a29.28 29.28 0 00-5.82-9.03C63.5 3.37 51.06 1.6 40.42 3.93a48.97 48.97 0 00-19.92 9.71 48.92 48.92 0 00-8.46 8.58c-2.08 2.76-5.6 7.2-6.22 10.92 2.8-.49 5.28-4.2 8.01-5.17 1.45-.51 3.11.32 2.1 2.23-.81 1.55-3.44 2.54-4.78 3.37-1.48.93-2.98 1.8-4.54 2.56-1.13.56-3.39 2.33-4.6 1.75C-.12 36.87.3 28.57.23 26.26c0-.94.03.95 0 0z" fill="#0D65D8"/></svg>'
    );
    transform-origin: center;
  }

  &:first-child::before {
    display: none;

    @include bp(medium) {
      display: block;
    }
  }

  &:last-child::before {
    @include bp(medium) {
      display: none;
    }
  }

  &:nth-child(odd) {
    @include bp(medium) {
      justify-self: start;
      grid-column: 2 / span 1;
    }

    &::before {
      transform: scale(-1, 1) rotate(-75deg);
      top: -1rem;
      right: -7rem;

      @include bp(small) {
        right: -9rem;
        transform: scale(-1, 1) rotate(-120deg);
      }

      @include bp(medium) {
        top: 50%;
        right: auto;
        left: -10rem;
        transform: translate(0, -3.5rem);
      }
    }
  }

  &:nth-child(even) {
    justify-self: end;

    @include bp(medium) {
      grid-column: 1 / span 1;
    }

    &::before {
      transform: rotate(-75deg);
      top: -1rem;
      left: -7rem;

      @include bp(small) {
        left: -9rem;
        transform: rotate(-120deg);
      }

      @include bp(medium) {
        top: 100%;
        right: -3.5rem;
        left: auto;
        transform: rotate(-164deg) translate(0, -1.5rem);
      }
    }
  }
}

.c-reasons__img {
  display: block;
  margin-bottom: 3rem;
}

.c-reasons__heading {
  font-weight: normal;
  font-size: $font-size-intro;
  margin-bottom: 2rem;
}

.c-reasons__content {
  font-size: $font-size-smaller;
}
