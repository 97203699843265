.c-expertise {
  .o-layout {
    margin-top: 13rem;
  }

  .o-layout__item {
    margin-top: 4.5rem;

    @include bp(large) {
      margin-top: 0;
    }
  }
}

.c-expertise__item {
  margin: 3rem auto 0;
  max-width: 48rem;

  @include bp(large) {
    margin: 7.5rem 0 0;
  }

  &:first-child {
    @include bp(large) {
      margin-top: 0;
    }
  }
}
