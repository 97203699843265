/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox side-by-side system */

.o-layout {
  display: block;

  @include bp(large) {
    display: flex;
  }

  &--reversed {
    @include bp(large) {
      flex-direction: row-reverse;
    }
  }
}

.o-layout__item {
  margin-top: 4.5rem;
  flex: 1 1 auto;

  @include bp(large) {
    margin-top: 0;
    width: 50%;
  }

  &:first-child,
  &:empty {
    margin-top: 0;
  }

  &--collapse-margin {
    margin-top: 0;
  }

  &--space-right {
    @include bp(large) {
      padding-right: 10%;
    }
  }

  &--space-left {
    @include bp(large) {
      padding-left: 10%;
    }
  }

  &--align-center\@large {
    @include bp(large) {
      align-self: center;
    }
  }

  &--align-end\@large {
    @include bp(large) {
      align-self: flex-end;
    }
  }

  &--align-center\@xlarge {
    @include bp(xlarge) {
      align-self: center;
    }
  }

  &--align-end\@xlarge {
    @include bp(xlarge) {
      align-self: flex-end;
    }
  }
}
