.c-slider {
  position: relative;

  @include bp(medium) {
    margin-top: 4.5rem;
  }

  &--to-left {
    @include bp(medium) {
      margin-top: 0;
    }

    @include bp(xlarge) {
      padding-left: calc(50vw - #{$size-wrapper / 2});
      margin-left: calc(-50vw + #{$size-wrapper / 2});
      width: 50vw;
      overflow: hidden;
    }

    .tns-ovh {
      @include bp(xlarge) {
        overflow: visible;
      }
    }
  }

  &--full {
    margin-left: -$size-padding;
    margin-right: -$size-padding;

    @include bp(xlarge) {
      margin-left: calc(-50vw + #{$size-wrapper / 2});
      margin-right: calc(-50vw + #{$size-wrapper / 2});
    }
  }
}

.c-slider__nav {
  display: flex;
  margin: 0.5rem auto 0;
  padding: 0 $size-padding;
  justify-content: center;
}

.c-slider__indicator {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  margin: 0 0.4rem;
  padding: 0;
  border: 0;
  height: 4.8rem;
  width: 4.8rem;
  background: transparent;
  cursor: pointer;

  @include bp(medium) {
    width: auto;
  }

  &::before {
    content: '';
    display: block;
  }

  &:focus {
    outline: none;
  }

  .c-slider__nav--dashes & {
    align-items: flex-end;

    &::before {
      width: 4.4rem;
      height: 0.2rem;
      background: $color-blue-grey;
    }

    &.tns-nav-active::before {
      background: $color-primary;
    }
  }

  .c-slider__nav--dots & {
    &::before {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background: $color-light-grey;
    }

    &.tns-nav-active::before {
      background: var(--section-accent-color);
    }
  }
}

.c-slider__controls {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto 3rem;
}

.c-slider__btn {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border: 0;
  background: transparent;
}

.c-slider__icon {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  stroke: var(--section-accent-color);
  transform-origin: center;

  &--prev {
    transform: scaleX(-1);
  }
}
