.c-cta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4.5rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  &--center {
    justify-content: center;
  }
}

.c-cta__btn {
  min-width: 28rem;
  box-shadow: 0 4px 16px var(--section-accent-color-transparent);

  &.o-btn--primary {
    box-shadow: 0 4px 16px rgba($color-primary, 0.2);
  }

  &.o-btn--secondary {
    box-shadow: 0 4px 16px rgba($color-secondary, 0.2);
  }

  &.o-btn--tertiary {
    box-shadow: 0 4px 16px rgba($color-tertiary, 0.2);
  }

  .c-cta--centered & {
    margin: 0 2rem;
  }
}
