/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 6.5rem;

  @include bp(medium) {
    flex-direction: row;
    justify-content: space-between;
  }

  @include bp(large) {
    padding-bottom: 8.5rem;
  }

  @include bp(xlarge) {
    padding-bottom: 10.5rem;
  }
}

.c-footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4.5rem;

  @include bp(medium) {
    flex-direction: row;
    margin-top: 0;
  }
}

.c-footer__item {
  @include bp(medium) {
    margin: 0 0 0 5.5rem;
  }
}

.c-footer__link {
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  color: $color-blue-black;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
}
