@mixin h($lvl) {
  font-weight: 500;
  color: var(--section-headings-color);

  @if $lvl == 1 {
    font-size: 3.4rem;
  }
  @else if $lvl == 2 {
    font-size: 3rem;
  }
  @else if $lvl == 3 {
    font-size: 2.4rem;
  }
  @else if $lvl == 4 {
    font-size: $font-size-intro;
  }
  @else if $lvl == 5 {
    font-size: $font-size-text;
  }
  @else if $lvl == 6 {
    font-size: $font-size-smaller;
  }

  @if $lvl < 5 {
    margin-bottom: 3rem;
  }
  @else {
    margin-bottom: $font-size-smaller;
  }
}
