.o-wysiwyg {
  max-width: 78rem;
  margin: 0 auto;
  font-size: $font-size-text;
  line-height: 1.5;
  color: var(--section-text-color);

  h1,
  h2,
  h3,
  h4 {
    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h5,
  h6 {
    margin-top: $font-size-text;

    &:first-child {
      margin-top: 0;
    }
  }

  b,
  strong {
    font-weight: 500;
  }

  small {
    font-size: $font-size-smaller;
  }

  ol {
    margin-left: 3.3rem;
  }

  ul {
    @include custom-list(inherit);
    line-height: 1.3;

    > li {
      position: relative;
      padding-left: 3.3rem;

      &::before {
        content: '';
        position: absolute;
        top: 0.4rem;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
        background: var(--section-accent-color);
      }
    }
  }

  pre {
    margin-bottom: 2.6rem;
    padding: 5rem 5rem 3.7rem;
    font-style: normal;
    background: $color-white;
    color: $color-blue-black;
    box-shadow: 0 16px 16px rgba(50, 50, 71, 0.08);
    border-radius: 2 * $global-radius;
  }

  blockquote {
    position: relative;
    margin: 3.7rem 0;
    padding-left: 8rem;
    max-width: 66rem;
    min-height: 2.5em;
    font-size: $font-size-intro;
    line-height: 1.25;
    font-style: normal;
    quotes: none;

    &::before {
      position: absolute;
      top: 0;
      left: -0.025em;
      content: '“';
      font-family: Times, 'Times New Roman', serif;
      font-weight: 700;
      font-size: 12rem;
      line-height: 0.75;
      width: 5.8rem;
      height: 4.2rem;
      color: var(--section-accent-color);
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
}
