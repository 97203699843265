/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.o-btn {
  --btn-label-color: var(--section-on-accent-color);
  --btn-bg-color: var(--section-accent-color);

  display: inline-flex; /* [1] */
  align-items: center;
  justify-content: center;
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center; /* [4] */
  text-decoration: none;
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: 1.3rem;
  min-width: 15rem;
  min-height: 5rem;
  transition: $global-transition;
  border-radius: $global-radius;
  color: var(--btn-label-color);
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 33.33%,
    rgba(0, 0, 0, 0.15) 66.67%,
    rgba(0, 0, 0, 0.15) 100%
  );
  background-size: auto 400%;
  background-color: var(--btn-bg-color);
  border: 2px solid var(--btn-bg-color);

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
    color: var(--btn-label-color);
  }

  &:hover {
    background-position: 0 bottom;
  }
}

.o-btn__icon {
  flex: 0 0 auto;
  margin: 0 0 0 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
}

.o-btn__label {
  flex: 1 0 auto;
}

/* Style variants
   ========================================================================== */

.o-btn--primary {
  --btn-label-color: #{$color-white};
  --btn-bg-color: #{$color-primary};
}

.o-btn--secondary {
  --btn-label-color: #{$color-white};
  --btn-bg-color: #{$color-secondary};
}

.o-btn--tertiary {
  --btn-label-color: #{$color-blue-black};
  --btn-bg-color: #{$color-tertiary};
}

.o-btn--outline {
  --btn-label-color: #{$color-primary};
  --btn-bg-color: #{$color-white};

  border-color: $color-blue-grey;
}
