.o-illustration {
  display: block;
  margin: 4.5rem auto;
  width: 100%;
  max-width: 43rem;

  @include bp(large) {
    margin: 3.5rem 0;
    max-width: none;
  }
}
