.c-logos {
  display: grid;
  gap: 3.5rem;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: none;
  grid-auto-flow: row;
  align-items: center;

  @include bp(medium) {
    grid-template-columns: repeat(3, auto);
  }
}

.c-logos__item {
  &:nth-child(3n + 1) {
    justify-self: flex-start;
  }

  &:nth-child(3n + 2) {
    justify-self: center;
  }

  &:nth-child(3n) {
    justify-self: flex-end;
  }
}
