.c-media {
  position: relative;
}

.c-media__decor {
  position: absolute;
  z-index: 1;
  display: block;

  &--triangle {
    width: 13rem;
    height: 13rem;
    bottom: -2rem;
    right: -4rem;
    fill: $color-primary;
  }

  &--rectangle {
    width: 3.5rem;
    height: 10.5rem;
    left: -3.7rem;
    bottom: 0;
    fill: var(--section-accent-color);
  }
}

.c-media__content {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background: $color-blue-grey;

  &--1-1 {
    padding-bottom: 100%;
  }

  &--5-4 {
    padding-bottom: 80%;
  }

  &--10-11 {
    padding-bottom: 110%;
  }

  &--16-9 {
    padding-bottom: 56.25%;
  }

  &--21-9 {
    padding-bottom: 42.86%;
  }
}

.c-media__item,
.c-media__content .c-embed {
  position: absolute;
  z-index: 2;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-media__item,
.c-media__content .c-embed iframe,
.c-media__content .c-embed object {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-media--shadow .c-media__content {
  margin-bottom: 4rem;

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    display: block;
    top: 6rem;
    left: 40%;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    background: var(--section-accent-color);
  }
}

.c-media--rounded .c-media__content {
  overflow: hidden;
  border-radius: 1rem;
}

.c-media--full {
  margin-left: -$size-padding;
  margin-right: -$size-padding;

  @include bp(xlarge) {
    margin-left: calc(-50vw + #{$size-wrapper / 2});
    margin-right: calc(-50vw + #{$size-wrapper / 2});
  }
}
