/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  margin-bottom: 2em;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.5em;
}

.c-post__meta {
  margin: 3rem 0;
  font-size: 1.4rem;

  a {
    text-decoration: none;
  }
}

.c-post__content {
  margin-top: 4.5rem;

  @include bp(medium) {
    margin-top: 6rem;
  }

  a[href*='//']:not([href*='goodiewebsite.com']),
  a[rel='external'] {
    text-transform: uppercase;
    text-decoration: none;

    &::after {
      content: '';
      display: inline-block;
      width: 2.8rem;
      height: 2.8rem;
      vertical-align: top;
      margin: -0.4rem -0.1rem -0.4rem 0.5rem;
      background: svg-url(
        '<svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 15v6a2 2 0 01-2 2H7a2 2 0 01-2-2V10a2 2 0 012-2h6m4-3h6m0 0v6m0-6L12 16" stroke="' +
          $color-link +
          '" stroke-linecap="round" stroke-linejoin="round"/></svg>'
      );
    }
  }
}
