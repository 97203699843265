.c-sectioned-list {
  @include custom-list;
}

.c-sectioned-list__item {
  position: relative;
  padding-left: 3.3rem;
  padding-bottom: 2.5rem;
  margin-bottom: 3.3rem;
  border-bottom: 1px solid $color-transparent-grey;

  &::before {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: $color-tertiary;
  }
}
