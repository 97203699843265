@mixin bottom-line($width: 28rem) {
  &::after {
    content: '';
    display: block;
    margin-top: 4.5rem;
    width: $width;
    max-width: 100%;
    border-bottom: 1px solid $color-transparent-grey;
  }
}
