.c-contact-info__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::before {
    content: '';
    display: block;
    margin-bottom: 3.6rem;
    width: 3.6rem;
    border-bottom: 2px solid $color-blue-grey;
  }
}

.c-contact-info .o-wysiwyg {
  font-size: $font-size-smaller;
  text-transform: uppercase;
}

.c-contact-info a {
  font-size: $font-size-intro;
  font-weight: 500;
  color: $color-blue-black;
  text-transform: none;
  text-decoration: none;

  @include bp(medium) {
    font-size: 2.6rem;
  }

  &:hover {
    text-decoration: underline;
  }
}

.c-contact-info__image {
  @include bp(large) {
    margin-top: 28rem;
  }
}
