@charset "UTF-8";
/*
  Project: Goodie WP
  Author: Urszula Wielgosz
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Sizes
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 3.4rem;
  margin-bottom: 3rem;
}

h2 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 3rem;
  margin-bottom: 3rem;
}

h3 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 2.4rem;
  margin-bottom: 3rem;
}

h4 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

h5 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
}

h6 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fff;
  color: #67768b;
  font-family: "Rubik", sans-serif;
  font-size: 0.625rem;
  /* [1] */
  /* 10px */
  line-height: 1.3;
  /* [1] */
  line-height-step: 0.1rem;
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #0d65d8;
  font-weight: 500;
  text-decoration: underline;
}

a:hover {
  color: #150e63;
  text-decoration: underline;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.8rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 2rem;
  margin-top: 0.8rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.o-btn, .form-submit input {
  --btn-label-color: var(--section-on-accent-color);
  --btn-bg-color: var(--section-accent-color);
  display: inline-flex;
  /* [1] */
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  /* [4] */
  text-decoration: none;
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: 1.3rem;
  min-width: 15rem;
  min-height: 5rem;
  transition: all 300ms ease-in-out;
  border-radius: 5px;
  color: var(--btn-label-color);
  background-image: linear-gradient(to bottom, transparent 0%, transparent 33.33%, rgba(0, 0, 0, 0.15) 66.67%, rgba(0, 0, 0, 0.15) 100%);
  background-size: auto 400%;
  background-color: var(--btn-bg-color);
  border: 2px solid var(--btn-bg-color);
}

.o-btn, .form-submit input, .o-btn:hover, .form-submit input:hover, .o-btn:active, .form-submit input:active, .o-btn:focus, .form-submit input:focus {
  text-decoration: none;
  /* [4] */
  color: var(--btn-label-color);
}

.o-btn:hover, .form-submit input:hover {
  background-position: 0 bottom;
}

.o-btn__icon {
  flex: 0 0 auto;
  margin: 0 0 0 1.2rem;
  width: 2.4rem;
  height: 2.4rem;
}

.o-btn__label {
  flex: 1 0 auto;
}

/* Style variants
   ========================================================================== */
.o-btn--primary, .form-submit input {
  --btn-label-color: #fff;
  --btn-bg-color: #0d65d8;
}

.o-btn--secondary {
  --btn-label-color: #fff;
  --btn-bg-color: #4aedc2;
}

.o-btn--tertiary {
  --btn-label-color: #150e63;
  --btn-bg-color: #facd23;
}

.o-btn--outline {
  --btn-label-color: #0d65d8;
  --btn-bg-color: #fff;
  border-color: #90a0b7;
}

.o-h1 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 3.4rem;
  margin-bottom: 3rem;
}

.o-h2 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 3rem;
  margin-bottom: 3rem;
}

.o-h3 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 2.4rem;
  margin-bottom: 3rem;
}

.o-h4 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

.o-h5 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
}

.o-h6 {
  font-weight: 500;
  color: var(--section-headings-color);
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}

.o-illustration {
  display: block;
  margin: 4.5rem auto;
  width: 100%;
  max-width: 43rem;
}

@media (min-width: 1024px) {
  .o-illustration {
    margin: 3.5rem 0;
    max-width: none;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox side-by-side system */
.o-layout {
  display: block;
}

@media (min-width: 1024px) {
  .o-layout {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .o-layout--reversed {
    flex-direction: row-reverse;
  }
}

.o-layout__item {
  margin-top: 4.5rem;
  flex: 1 1 auto;
}

@media (min-width: 1024px) {
  .o-layout__item {
    margin-top: 0;
    width: 50%;
  }
}

.o-layout__item:first-child, .o-layout__item:empty {
  margin-top: 0;
}

.o-layout__item--collapse-margin {
  margin-top: 0;
}

@media (min-width: 1024px) {
  .o-layout__item--space-right {
    padding-right: 10%;
  }
}

@media (min-width: 1024px) {
  .o-layout__item--space-left {
    padding-left: 10%;
  }
}

@media (min-width: 1024px) {
  .o-layout__item--align-center\@large {
    align-self: center;
  }
}

@media (min-width: 1024px) {
  .o-layout__item--align-end\@large {
    align-self: flex-end;
  }
}

@media (min-width: 1240px) {
  .o-layout__item--align-center\@xlarge {
    align-self: center;
  }
}

@media (min-width: 1240px) {
  .o-layout__item--align-end\@xlarge {
    align-self: flex-end;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin: 0;
}

.o-section {
  --section-on-accent-color: #fff;
  --section-accent-color: #4aedc2;
  --section-accent-color-transparent: rgba(74, 237, 194, 0.2);
  --section-text-color: #67768b;
  --section-headings-color: #150e63;
  position: relative;
  padding: 4.5rem 0;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .o-section {
    padding: 6.5rem 0;
  }
}

@media (min-width: 1240px) {
  .o-section {
    padding: 8.5rem 0;
  }
}

.o-section--primary {
  --section-accent-color: #0d65d8;
  --section-accent-color-transparent: rgba(13, 101, 216, 0.2);
}

.o-section--tertiary {
  --section-accent-color: #facd23;
  --section-accent-color-transparent: rgba(250, 205, 35, 0.2);
}

.o-section--blue-black {
  --section-on-accent-color: #150e63;
  --section-text-color: #e5e5e5;
  --section-headings-color: #fff;
  margin: 4.5rem 0;
  background: #150e63;
}

@media (min-width: 1024px) {
  .o-section--blue-black {
    margin: 6.5rem 0;
  }
}

@media (min-width: 1240px) {
  .o-section--blue-black {
    margin: 8.5rem 0;
  }
}

.o-section--shifted-blue-black {
  --section-on-accent-color: #150e63;
  --section-text-color: #e5e5e5;
  --section-headings-color: #fff;
  margin: 4.5rem 0;
  background: #150e63;
}

@media (min-width: 1024px) {
  .o-section--shifted-blue-black {
    margin: 6.5rem 0;
  }
}

@media (min-width: 1240px) {
  .o-section--shifted-blue-black {
    margin: 8.5rem 0;
  }
}

.o-section--blue-black {
  --section-accent-color: #4aedc2;
  --section-accent-color-transparent: rgba(74, 237, 194, 0.2);
}

.o-section--shifted-blue-black {
  --section-accent-color: #facd23;
  --section-accent-color-transparent: rgba(250, 205, 35, 0.2);
}

@media (min-width: 1024px) {
  .o-section--shifted-blue-black {
    background: transparent;
  }
}

@media (min-width: 1024px) {
  .o-section--shifted-blue-black::before {
    content: '';
    position: absolute;
    top: 3rem;
    bottom: 0;
    right: 0;
    width: calc(100% - 10rem);
    background: #150e63;
  }
}

@media (min-width: 1240px) {
  .o-section--shifted-blue-black::before {
    top: 5rem;
    width: calc(50% + 49rem);
  }
}

@media (min-width: 1024px) {
  .o-section__pulled-up {
    margin-top: -6.5rem;
  }
}

@media (min-width: 1240px) {
  .o-section__pulled-up {
    margin-top: -8.5rem;
  }
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

.o-tag {
  margin-bottom: 3rem;
  color: #0d65d8;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.o-tag--accent {
  color: var(--section-accent-color);
}

.o-text {
  max-width: 66rem;
}

.o-text:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
  height: 1px;
}

.o-text--center {
  text-align: center;
  margin: 0 auto;
}

.o-text--bottom-space {
  margin-bottom: 9rem;
}

.o-text--center .o-text__heading {
  max-width: 56rem;
  margin-left: auto;
  margin-right: auto;
}

.o-text__content--bottom-line::after {
  content: '';
  display: block;
  margin-top: 4.5rem;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid rgba(102, 102, 102, 0.24);
}

.o-text__arrow.c-arrow-decor {
  display: inline-block;
  vertical-align: text-top;
  margin-bottom: 0;
  margin-left: 6rem;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 124rem;
}

.o-wysiwyg {
  max-width: 78rem;
  margin: 0 auto;
  font-size: 1.8rem;
  line-height: 1.5;
  color: var(--section-text-color);
}

.o-wysiwyg h1,
.o-wysiwyg h2,
.o-wysiwyg h3,
.o-wysiwyg h4 {
  margin-top: 3rem;
}

.o-wysiwyg h1:first-child,
.o-wysiwyg h2:first-child,
.o-wysiwyg h3:first-child,
.o-wysiwyg h4:first-child {
  margin-top: 0;
}

.o-wysiwyg h5,
.o-wysiwyg h6 {
  margin-top: 1.8rem;
}

.o-wysiwyg h5:first-child,
.o-wysiwyg h6:first-child {
  margin-top: 0;
}

.o-wysiwyg b,
.o-wysiwyg strong {
  font-weight: 500;
}

.o-wysiwyg small {
  font-size: 1.6rem;
}

.o-wysiwyg ol {
  margin-left: 3.3rem;
}

.o-wysiwyg ul {
  list-style: none;
  font-size: inherit;
  margin-left: 0;
  color: var(--section-text-color);
  line-height: 1.3;
}

.o-wysiwyg ul > li {
  position: relative;
  padding-left: 3.3rem;
}

.o-wysiwyg ul > li::before {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background: var(--section-accent-color);
}

.o-wysiwyg pre {
  margin-bottom: 2.6rem;
  padding: 5rem 5rem 3.7rem;
  font-style: normal;
  background: #fff;
  color: #150e63;
  box-shadow: 0 16px 16px rgba(50, 50, 71, 0.08);
  border-radius: 10px;
}

.o-wysiwyg blockquote {
  position: relative;
  margin: 3.7rem 0;
  padding-left: 8rem;
  max-width: 66rem;
  min-height: 2.5em;
  font-size: 2.2rem;
  line-height: 1.25;
  font-style: normal;
  quotes: none;
}

.o-wysiwyg blockquote::before {
  position: absolute;
  top: 0;
  left: -0.025em;
  content: '“';
  font-family: Times, 'Times New Roman', serif;
  font-weight: 700;
  font-size: 12rem;
  line-height: 0.75;
  width: 5.8rem;
  height: 4.2rem;
  color: var(--section-accent-color);
}

.o-wysiwyg > :last-child {
  margin-bottom: 0;
}

.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both;
}

.tns-t-ct > div {
  width: 1.42857%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.c-arrow-decor {
  display: block;
  margin: -3rem 0 3rem auto;
  height: 6.2rem;
  width: 7.2rem;
  fill: var(--section-accent-color);
  transform-origin: center;
  transform: rotate(60deg);
}

@media (min-width: 1024px) {
  .c-arrow-decor {
    margin-top: 0;
    margin-right: auto;
    transform: none;
  }
}

.c-arrow-list {
  list-style: none;
  font-size: 1.6rem;
  margin-left: 0;
  color: var(--section-text-color);
}

.c-arrow-list__item {
  display: flex;
  align-items: flex-start;
}

.c-arrow-list__bullet {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  margin: -0.2rem 1.3rem -0.1rem 0;
  stroke: var(--section-accent-color);
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  font-size: 1.4rem;
}

.comment-notes {
  margin-bottom: 4rem;
}

.comment-form-comment,
.comment-form-author,
.comment-form-email,
.comment-form-url {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 3rem;
}

.comment-form-comment label,
.comment-form-author label,
.comment-form-email label,
.comment-form-url label {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 100%;
  font-size: 1rem;
  transition: transform 200ms ease-in-out;
}

.comment-form-comment input,
.comment-form-comment textarea,
.comment-form-author input,
.comment-form-author textarea,
.comment-form-email input,
.comment-form-email textarea,
.comment-form-url input,
.comment-form-url textarea {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.6rem 2rem;
  min-height: 5rem;
  background: #fff;
  border: 1px solid #67768b;
  border-radius: 5px;
  font-size: 1.3rem;
}

.comment-form-comment input:focus,
.comment-form-comment textarea:focus,
.comment-form-author input:focus,
.comment-form-author textarea:focus,
.comment-form-email input:focus,
.comment-form-email textarea:focus,
.comment-form-url input:focus,
.comment-form-url textarea:focus {
  outline: none;
  box-shadow: 0 0 0 1px #0d65d8;
}

.comment-form-comment textarea,
.comment-form-author textarea,
.comment-form-email textarea,
.comment-form-url textarea {
  min-height: 14rem;
}

.comment-form-comment.is-empty label,
.comment-form-author.is-empty label,
.comment-form-email.is-empty label,
.comment-form-url.is-empty label {
  transform: translate(2.8rem, 3.3rem) scale(1.3);
  pointer-events: none;
}

.comment-form-comment.is-invalid label,
.comment-form-author.is-invalid label,
.comment-form-email.is-invalid label,
.comment-form-url.is-invalid label {
  color: #ff647c;
}

.comment-form-comment.is-invalid input,
.comment-form-comment.is-invalid textarea,
.comment-form-author.is-invalid input,
.comment-form-author.is-invalid textarea,
.comment-form-email.is-invalid input,
.comment-form-email.is-invalid textarea,
.comment-form-url.is-invalid input,
.comment-form-url.is-invalid textarea {
  border-color: #ff647c;
  box-shadow: 0 8px 8px rgba(255, 100, 124, 0.08);
}

.comment-form-cookies-consent {
  display: flex;
  align-items: flex-start;
  font-size: 1.3rem;
  margin-bottom: 3rem;
}

.comment-form-cookies-consent label {
  margin: 0.2rem 0 0 1.2rem;
}

.comment-form-cookies-consent input {
  width: 1.8rem;
  height: 1.8rem;
}

.form-submit {
  display: flex;
  justify-content: center;
  padding-top: 4.5rem;
  margin: 4.5rem 0;
  border-top: 1px solid rgba(102, 102, 102, 0.24);
  text-align: center;
}

.form-submit input {
  flex: 0 1 auto;
  width: 30rem;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 3rem;
}

.c-comment__moderation {
  font-style: italic;
  font-size: 1.4rem;
  color: var(--section-accent-color);
}

.c-comment__content {
  font-size: 1.4rem;
}

.c-contact-info__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-contact-info__content::before {
  content: '';
  display: block;
  margin-bottom: 3.6rem;
  width: 3.6rem;
  border-bottom: 2px solid #90a0b7;
}

.c-contact-info .o-wysiwyg {
  font-size: 1.6rem;
  text-transform: uppercase;
}

.c-contact-info a {
  font-size: 2.2rem;
  font-weight: 500;
  color: #150e63;
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-contact-info a {
    font-size: 2.6rem;
  }
}

.c-contact-info a:hover {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .c-contact-info__image {
    margin-top: 28rem;
  }
}

.c-cta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4.5rem 0;
}

.c-cta:last-child {
  margin-bottom: 0;
}

.c-cta--center {
  justify-content: center;
}

.c-cta__btn {
  min-width: 28rem;
  box-shadow: 0 4px 16px var(--section-accent-color-transparent);
}

.c-cta__btn.o-btn--primary, .form-submit input.c-cta__btn {
  box-shadow: 0 4px 16px rgba(13, 101, 216, 0.2);
}

.c-cta__btn.o-btn--secondary {
  box-shadow: 0 4px 16px rgba(74, 237, 194, 0.2);
}

.c-cta__btn.o-btn--tertiary {
  box-shadow: 0 4px 16px rgba(250, 205, 35, 0.2);
}

.c-cta--centered .c-cta__btn {
  margin: 0 2rem;
}

.c-embed iframe,
.c-embed object {
  display: block;
  width: 100%;
}

.c-expertise .o-layout {
  margin-top: 13rem;
}

.c-expertise .o-layout__item {
  margin-top: 4.5rem;
}

@media (min-width: 1024px) {
  .c-expertise .o-layout__item {
    margin-top: 0;
  }
}

.c-expertise__item {
  margin: 3rem auto 0;
  max-width: 48rem;
}

@media (min-width: 1024px) {
  .c-expertise__item {
    margin: 7.5rem 0 0;
  }
}

@media (min-width: 1024px) {
  .c-expertise__item:first-child {
    margin-top: 0;
  }
}

.c-faq {
  margin-top: 5rem;
}

.c-faq__details {
  display: block;
  padding: 0;
  margin-bottom: 2rem;
  border: 1px solid rgba(102, 102, 102, 0.24);
  border-radius: 5px;
  background: #fff;
  transition: box-shadow 500ms ease-in-out, margin 500ms ease-in-out;
}

.c-faq__details:last-child {
  margin-bottom: 0;
}

.c-faq__details.is-open,
.no-js .c-faq__details[open] {
  margin-bottom: 3.5rem;
  box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08), 0 16px 16px rgba(50, 50, 71, 0.08);
}

.c-faq__summary {
  display: block;
  position: relative;
  margin: 0;
  padding: 3.5rem 8rem 3.5rem 2rem;
  list-style: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.c-faq__summary::-webkit-details-marker {
  display: none;
}

.c-faq__summary:focus {
  outline: none;
}

.c-faq__close {
  display: block;
  position: absolute;
  top: 3.2rem;
  right: 3.2rem;
  width: 2.2rem;
  height: 2.2rem;
  color: #0d65d8;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.c-faq__details.is-open .c-faq__close,
.no-js .c-faq__details[open] .c-faq__close {
  opacity: 1;
}

.c-faq__inner {
  --content-height: auto;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  height: var(--content-height);
  transition: height 500ms ease-in-out;
}

.c-faq__content {
  padding: 0 8rem 3.5rem 2rem;
  font-size: 1.6rem;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 6.5rem;
}

@media (min-width: 768px) {
  .c-footer {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .c-footer {
    padding-bottom: 8.5rem;
  }
}

@media (min-width: 1240px) {
  .c-footer {
    padding-bottom: 10.5rem;
  }
}

.c-footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4.5rem;
}

@media (min-width: 768px) {
  .c-footer__links {
    flex-direction: row;
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .c-footer__item {
    margin: 0 0 0 5.5rem;
  }
}

.c-footer__link {
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  color: #150e63;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.c-header.is-stuck {
  box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08), 0 16px 16px rgba(50, 50, 71, 0.08);
}

.c-header__sentinel {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}

.c-header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.c-header__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  height: 4rem;
  width: 4rem;
  background: transparent;
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-header__btn {
    display: none;
  }
}

.c-header__btn:focus {
  outline: none;
}

.c-header__btn::before, .c-header__btn::after {
  content: '';
  display: block;
  width: 4rem;
  height: 0.2rem;
  background: #0d65d8;
}

.c-header__btn--open {
  flex-direction: column;
}

.c-header__btn--open::before, .c-header__btn--open::after {
  margin: 0.4rem 0;
}

.c-header__btn--close {
  position: absolute;
  top: 3.6rem;
  right: 3rem;
}

.c-header__btn--close::before, .c-header__btn--close::after {
  position: absolute;
  top: 50%;
  left: 0;
  margin: -0.1rem 0;
}

.c-header__btn--close::before {
  transform: rotate(45deg);
}

.c-header__btn--close::after {
  transform: rotate(-45deg);
}

.c-header__nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 21rem;
  padding: 11rem 3rem 3rem;
  background: #fff;
  transform: translate(100%, 0);
  transform-origin: right center;
  transition: transform 300ms ease-in-out;
}

@media (min-width: 768px) {
  .c-header__nav {
    position: static;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: auto;
    max-width: none;
    padding: 0;
    background: transparent;
    transform: none;
    transition: none;
  }
}

.c-header__nav.is-open {
  transform: translate(0, 0);
  box-shadow: 0 0 32px rgba(50, 50, 71, 0.08), 0 0 16px rgba(50, 50, 71, 0.08);
}

@media (min-width: 768px) {
  .c-header__nav.is-open {
    transform: none;
    box-shadow: none;
  }
}

.c-header__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .c-header__cta {
    flex-direction: row;
  }
}

.c-header__cta-item {
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .c-header__cta-item {
    margin: 0 0 0 2rem;
  }
}

.c-header__nav-links {
  width: 100%;
  padding: 1.5rem 0 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (min-width: 768px) {
  .c-header__nav-links {
    flex-direction: row;
    justify-content: flex-end;
    text-align: left;
  }
}

.c-header__nav-item,
.c-header__nav-subitem {
  position: relative;
  margin: 0;
}

.c-header__nav-link,
.c-header__nav-sublink {
  display: flex;
  align-items: center;
  color: #0d65d8;
  font-weight: 500;
  text-decoration: none;
}

.c-header__nav-caret {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  width: 25px;
  height: 25px;
}

@media (min-width: 768px) {
  .c-header__nav-caret {
    width: 15px;
    height: 15px;
  }
}

.c-header__nav-caret span {
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #0d65d8;
  transition: all 300ms ease-in-out;
}

.c-header__nav-link:hover .c-header__nav-caret span,
.c-header__nav-link:focus .c-header__nav-caret span,
.current-menu-item .c-header__nav-caret span {
  border-top-color: #67768b;
}

.c-header__nav-link:hover .c-header__nav-caret span,
.c-header__nav-link:focus .c-header__nav-caret span,
.c-header__nav-item.is-open .c-header__nav-caret span {
  transform: rotate(180deg);
}

.c-header__nav-link {
  padding: 1rem 0;
  justify-content: space-between;
  font-size: 1.6rem;
  transition: all 300ms ease-in-out;
  text-align: left;
}

.c-header__nav-link:hover, .c-header__nav-link:focus,
.current-menu-item > .c-header__nav-link {
  color: #67768b;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-header__nav-link {
    justify-content: flex-start;
    padding: 1rem 2.5rem;
  }
}

@media (min-width: 1024px) {
  .c-header__nav-link {
    padding: 1rem 3.5rem;
  }
}

.c-header__nav-dropdown {
  position: relative;
  display: none;
  transition: all 300ms ease-in-out;
}

.c-header__nav-item.is-open .c-header__nav-dropdown {
  display: block;
}

@media (min-width: 768px) {
  .c-header__nav-dropdown {
    display: block;
    position: absolute;
    min-width: 150px;
    top: 100%;
    left: 0;
    padding: 1rem;
    margin-top: 3rem;
    background-color: #fff;
    box-shadow: 1px 1px 3px rgba(13, 101, 216, 0.2);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    visibility: hidden;
    opacity: 0;
  }
  .c-header__nav-item.is-open .c-header__nav-dropdown {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.c-header__nav-sublink {
  font-size: 1.5rem;
  padding: 0.5rem;
  text-align: left;
}

.c-header__nav-sublink:hover, .c-header__nav-sublink:focus,
.current-menu-item > .c-header__nav-sublink {
  color: #67768b;
  text-decoration: none;
}

@media (min-width: 768px) {
  .c-how-it-works {
    margin-right: 30%;
  }
}

@media (min-width: 1024px) {
  .c-how-it-works {
    margin: 0 0 0 12%;
  }
}

@media (min-width: 1024px) {
  .c-how-it-works .o-tag {
    text-align: center;
  }
}

.c-icons-inline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
}

.c-interlude .o-text__heading {
  max-width: 48rem;
  color: var(--section-accent-color);
}

.c-intro::after {
  content: '';
  display: block;
  margin-top: 4.5rem;
  width: 28rem;
  max-width: 100%;
  border-bottom: 1px solid rgba(102, 102, 102, 0.24);
}

.c-intro .o-wysiwyg {
  line-height: 1.6667;
}

.c-logo {
  display: block;
  width: 14.7rem;
  height: 7.2rem;
}

.c-logo__icon {
  display: block;
  width: 100%;
  height: 100%;
}

.c-logos {
  display: grid;
  gap: 3.5rem;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: none;
  grid-auto-flow: row;
  align-items: center;
}

@media (min-width: 768px) {
  .c-logos {
    grid-template-columns: repeat(3, auto);
  }
}

.c-logos__item:nth-child(3n + 1) {
  justify-self: flex-start;
}

.c-logos__item:nth-child(3n + 2) {
  justify-self: center;
}

.c-logos__item:nth-child(3n) {
  justify-self: flex-end;
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  margin: 0 0 2em 0;
}

.c-main-nav__item {
  font-weight: bold;
  list-style: none;
  margin: 0 1em 0 0;
  padding: 0;
}

.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

.c-main-nav__link {
  border-bottom: 1px solid #67768b;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__link {
  border-bottom-color: #67768b;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  background: #fff;
  border-bottom: 1px solid #67768b;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: #67768b;
}

.c-media {
  position: relative;
}

.c-media__decor {
  position: absolute;
  z-index: 1;
  display: block;
}

.c-media__decor--triangle {
  width: 13rem;
  height: 13rem;
  bottom: -2rem;
  right: -4rem;
  fill: #0d65d8;
}

.c-media__decor--rectangle {
  width: 3.5rem;
  height: 10.5rem;
  left: -3.7rem;
  bottom: 0;
  fill: var(--section-accent-color);
}

.c-media__content {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background: #90a0b7;
}

.c-media__content--1-1 {
  padding-bottom: 100%;
}

.c-media__content--5-4 {
  padding-bottom: 80%;
}

.c-media__content--10-11 {
  padding-bottom: 110%;
}

.c-media__content--16-9 {
  padding-bottom: 56.25%;
}

.c-media__content--21-9 {
  padding-bottom: 42.86%;
}

.c-media__item,
.c-media__content .c-embed {
  position: absolute;
  z-index: 2;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-media__item,
.c-media__content .c-embed iframe,
.c-media__content .c-embed object {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-media--shadow .c-media__content {
  margin-bottom: 4rem;
}

.c-media--shadow .c-media__content::before {
  content: '';
  position: absolute;
  z-index: 0;
  display: block;
  top: 6rem;
  left: 40%;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  background: var(--section-accent-color);
}

.c-media--rounded .c-media__content {
  overflow: hidden;
  border-radius: 1rem;
}

.c-media--full {
  margin-left: -3rem;
  margin-right: -3rem;
}

@media (min-width: 1240px) {
  .c-media--full {
    margin-left: calc(-50vw + 59rem);
    margin-right: calc(-50vw + 59rem);
  }
}

.c-page__header {
  max-width: 48rem;
  margin: 0 auto 7.2rem;
  text-align: center;
  font-size: 1.8rem;
}

.c-page__title::after {
  content: '';
  display: block;
  width: 3.6rem;
  margin: 3.6rem auto;
  border-top: 2px solid #67768b;
}

.c-page__excerpt {
  color: #67768b;
}

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 78rem;
  margin: 0 auto;
  padding: 3rem 0;
  font-size: 1.8rem;
}

.c-pagination__pages {
  display: none;
}

@media (min-width: 768px) {
  .c-pagination__pages {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.c-pagination__page {
  margin: 0 1rem;
}

.c-pagination__dir,
.c-pagination__link,
.c-pagination__plain {
  min-width: 4rem;
}

.c-pagination__plain,
.c-pagination__dir.o-btn--outline {
  cursor: default;
}

.c-pagination__plain:hover,
.c-pagination__dir.o-btn--outline:hover {
  background-position: 0 0;
}

.c-post__author {
  max-width: 78rem;
  margin: 3rem auto 0;
}

.c-post__author-inner {
  display: block;
}

@media (min-width: 480px) {
  .c-post__author-inner {
    display: flex;
  }
}

.c-post__author-avatar {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  margin-bottom: 2rem;
}

@media (min-width: 480px) {
  .c-post__author-avatar {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .c-post__author-avatar {
    width: 150px;
    height: 150px;
  }
}

.c-post__author-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.c-post-author__info {
  font-size: 1.6rem;
}

@media (min-width: 480px) {
  .c-post-author__info {
    padding-left: 1rem;
  }
}

@media (min-width: 768px) {
  .c-post-author__info {
    padding-left: 2rem;
  }
}

.c-post-author__links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.c-post-author__links li:not(:first-child) ::before {
  content: '|';
  margin: 0 0.5rem;
}

.c-post-author__links a {
  text-decoration: none;
  color: #67768b;
}

.c-post-author__links a:hover {
  color: #0d65d8;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}

.c-post:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
  height: 1px;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-size: 1rem;
  font-style: normal;
  margin-top: 0.5em;
}

.c-post__meta {
  margin: 3rem 0;
  font-size: 1.4rem;
}

.c-post__meta a {
  text-decoration: none;
}

.c-post__content {
  margin-top: 4.5rem;
}

@media (min-width: 768px) {
  .c-post__content {
    margin-top: 6rem;
  }
}

.c-post__content a[href*='//']:not([href*='goodiewebsite.com']),
.c-post__content a[rel='external'] {
  text-transform: uppercase;
  text-decoration: none;
}

.c-post__content a[href*='//']:not([href*='goodiewebsite.com'])::after,
.c-post__content a[rel='external']::after {
  content: '';
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  vertical-align: top;
  margin: -0.4rem -0.1rem -0.4rem 0.5rem;
  background: url("data:image/svg+xml,%3Csvg width='28' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 15v6a2 2 0 01-2 2H7a2 2 0 01-2-2V10a2 2 0 012-2h6m4-3h6m0 0v6m0-6L12 16' stroke='%230d65d8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.c-reasons {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  row-gap: 4rem;
}

@media (min-width: 768px) {
  .c-reasons {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    column-gap: 4rem;
  }
}

@media (min-width: 1024px) {
  .c-reasons {
    margin-right: -6rem;
  }
}

@media (min-width: 1240px) {
  .c-reasons {
    grid-template-columns: 1fr auto;
    column-gap: 5rem;
  }
}

.c-reasons::before, .c-reasons::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.c-reasons::before {
  width: 87rem;
  height: 87rem;
  background: rgba(74, 237, 194, 0.25);
}

@media (min-width: 768px) {
  .c-reasons::before {
    width: 51.2rem;
    height: 51.2rem;
  }
}

.c-reasons::after {
  width: 62rem;
  height: 62rem;
  background: rgba(46, 212, 122, 0.5);
}

@media (min-width: 768px) {
  .c-reasons::after {
    width: 33.8rem;
    height: 33.8rem;
  }
}

.c-reasons__item {
  position: relative;
  z-index: 1;
  max-width: 28rem;
  padding: 2rem 3rem 4.5rem;
  background: #fff;
  border: 1px solid rgba(102, 102, 102, 0.24);
  border-radius: 1rem;
  box-shadow: 0 24px 32px rgba(50, 50, 71, 0.08), 0 16px 16px rgba(50, 50, 71, 0.08);
}

@media (min-width: 768px) {
  .c-reasons__item {
    grid-row-end: span 2;
    max-width: 29.5rem;
    min-height: 34rem;
  }
}

.c-reasons__item:first-child {
  border-color: #0d65d8;
}

.c-reasons__item::before {
  content: '';
  position: absolute;
  width: 8.1rem;
  height: 3.8rem;
  background: url("data:image/svg+xml,%3Csvg width='81' height='38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.22 26.26c0-.9-.33-2.82.43-3.37 1.02-.74 1.41.69 1.7 1.6.51 1.79.28 4.78 1.21 6.24C9.76 18.08 19.24 8.61 31.02 3.74 42.74-1.1 57.11-2.04 68.14 5.72c5.4 3.79 9.9 9.3 11.6 16.57.13.63.96 3.67-.16 3.55-.58-.06-1.63-3.99-1.9-4.65a29.28 29.28 0 00-5.82-9.03C63.5 3.37 51.06 1.6 40.42 3.93a48.97 48.97 0 00-19.92 9.71 48.92 48.92 0 00-8.46 8.58c-2.08 2.76-5.6 7.2-6.22 10.92 2.8-.49 5.28-4.2 8.01-5.17 1.45-.51 3.11.32 2.1 2.23-.81 1.55-3.44 2.54-4.78 3.37-1.48.93-2.98 1.8-4.54 2.56-1.13.56-3.39 2.33-4.6 1.75C-.12 36.87.3 28.57.23 26.26c0-.94.03.95 0 0z' fill='%230D65D8'/%3E%3C/svg%3E");
  transform-origin: center;
}

.c-reasons__item:first-child::before {
  display: none;
}

@media (min-width: 768px) {
  .c-reasons__item:first-child::before {
    display: block;
  }
}

@media (min-width: 768px) {
  .c-reasons__item:last-child::before {
    display: none;
  }
}

@media (min-width: 768px) {
  .c-reasons__item:nth-child(odd) {
    justify-self: start;
    grid-column: 2 / span 1;
  }
}

.c-reasons__item:nth-child(odd)::before {
  transform: scale(-1, 1) rotate(-75deg);
  top: -1rem;
  right: -7rem;
}

@media (min-width: 480px) {
  .c-reasons__item:nth-child(odd)::before {
    right: -9rem;
    transform: scale(-1, 1) rotate(-120deg);
  }
}

@media (min-width: 768px) {
  .c-reasons__item:nth-child(odd)::before {
    top: 50%;
    right: auto;
    left: -10rem;
    transform: translate(0, -3.5rem);
  }
}

.c-reasons__item:nth-child(even) {
  justify-self: end;
}

@media (min-width: 768px) {
  .c-reasons__item:nth-child(even) {
    grid-column: 1 / span 1;
  }
}

.c-reasons__item:nth-child(even)::before {
  transform: rotate(-75deg);
  top: -1rem;
  left: -7rem;
}

@media (min-width: 480px) {
  .c-reasons__item:nth-child(even)::before {
    left: -9rem;
    transform: rotate(-120deg);
  }
}

@media (min-width: 768px) {
  .c-reasons__item:nth-child(even)::before {
    top: 100%;
    right: -3.5rem;
    left: auto;
    transform: rotate(-164deg) translate(0, -1.5rem);
  }
}

.c-reasons__img {
  display: block;
  margin-bottom: 3rem;
}

.c-reasons__heading {
  font-weight: normal;
  font-size: 2.2rem;
  margin-bottom: 2rem;
}

.c-reasons__content {
  font-size: 1.6rem;
}

.c-related-posts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

@media (min-width: 768px) {
  .c-related-posts {
    margin-top: 4.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .c-related-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-related-posts__item {
  margin-bottom: 0;
}

.c-related-posts__link {
  text-decoration: none;
  font-weight: normal;
}

.c-related-posts__link:hover {
  text-decoration: none;
}

.c-related-posts__link .c-media {
  margin-bottom: 4.5rem;
}

.c-related-posts__link .o-tag {
  margin-bottom: 2rem;
}

.c-sectioned-list {
  list-style: none;
  font-size: 1.6rem;
  margin-left: 0;
  color: var(--section-text-color);
}

.c-sectioned-list__item {
  position: relative;
  padding-left: 3.3rem;
  padding-bottom: 2.5rem;
  margin-bottom: 3.3rem;
  border-bottom: 1px solid rgba(102, 102, 102, 0.24);
}

.c-sectioned-list__item::before {
  content: '';
  position: absolute;
  top: 0.4rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #facd23;
}

.c-skills {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none;
  column-gap: 6rem;
  row-gap: 3.5rem;
}

@media (min-width: 768px) {
  .c-skills {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5rem;
  }
}

@media (min-width: 1024px) {
  .c-skills {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1240px) {
  .c-skills {
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-skills__item {
  padding: 3.5rem 0 0;
  grid-column-end: span 1;
  grid-row-end: span 1;
}

@media (min-width: 768px) {
  .c-skills__item {
    padding-top: 7rem;
  }
}

.c-skills__item::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin-top: -3.5rem;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 768px) {
  .c-skills__item::before {
    margin-top: -7rem;
    display: none;
  }
}

.c-skills__item:first-child::before {
  display: none;
}

@media (min-width: 768px) {
  .c-skills__item:nth-child(2n + 3)::before {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-skills__item:nth-child(2n + 3)::before {
    display: none;
  }
}

@media (min-width: 1024px) {
  .c-skills__item:nth-child(3n + 4)::before {
    display: block;
  }
}

@media (min-width: 1240px) {
  .c-skills__item:nth-child(3n + 4)::before {
    display: none;
  }
}

@media (min-width: 1240px) {
  .c-skills__item:nth-child(4n + 5)::before {
    display: block;
  }
}

.c-skills__term {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 8.5rem;
  margin-bottom: 3.5rem;
  font-size: 2.4rem;
  font-weight: 500;
  color: #150e63;
}

.c-skills__logo {
  display: block;
  flex: 1 1 auto;
  max-width: 75%;
  height: 100%;
  filter: grayscale(100%);
}

.c-skills__definition strong {
  color: #150e63;
}

.c-slider {
  position: relative;
}

@media (min-width: 768px) {
  .c-slider {
    margin-top: 4.5rem;
  }
}

@media (min-width: 768px) {
  .c-slider--to-left {
    margin-top: 0;
  }
}

@media (min-width: 1240px) {
  .c-slider--to-left {
    padding-left: calc(50vw - 59rem);
    margin-left: calc(-50vw + 59rem);
    width: 50vw;
    overflow: hidden;
  }
}

@media (min-width: 1240px) {
  .c-slider--to-left .tns-ovh {
    overflow: visible;
  }
}

.c-slider--full {
  margin-left: -3rem;
  margin-right: -3rem;
}

@media (min-width: 1240px) {
  .c-slider--full {
    margin-left: calc(-50vw + 59rem);
    margin-right: calc(-50vw + 59rem);
  }
}

.c-slider__nav {
  display: flex;
  margin: 0.5rem auto 0;
  padding: 0 3rem;
  justify-content: center;
}

.c-slider__indicator {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  margin: 0 0.4rem;
  padding: 0;
  border: 0;
  height: 4.8rem;
  width: 4.8rem;
  background: transparent;
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-slider__indicator {
    width: auto;
  }
}

.c-slider__indicator::before {
  content: '';
  display: block;
}

.c-slider__indicator:focus {
  outline: none;
}

.c-slider__nav--dashes .c-slider__indicator {
  align-items: flex-end;
}

.c-slider__nav--dashes .c-slider__indicator::before {
  width: 4.4rem;
  height: 0.2rem;
  background: #90a0b7;
}

.c-slider__nav--dashes .c-slider__indicator.tns-nav-active::before {
  background: #0d65d8;
}

.c-slider__nav--dots .c-slider__indicator::before {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background: #e5e5e5;
}

.c-slider__nav--dots .c-slider__indicator.tns-nav-active::before {
  background: var(--section-accent-color);
}

.c-slider__controls {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto 3rem;
}

.c-slider__btn {
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border: 0;
  background: transparent;
}

.c-slider__icon {
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  stroke: var(--section-accent-color);
  transform-origin: center;
}

.c-slider__icon--prev {
  transform: scaleX(-1);
}

.c-tease {
  max-width: 78rem;
  margin: 0 auto;
  padding: 3rem 0;
  border-bottom: 1px solid rgba(102, 102, 102, 0.24);
}

.c-tease__thumbnail {
  display: block;
  margin-bottom: 3rem;
}

.c-tease__link {
  text-decoration: none;
}

.c-tease__heading {
  margin-bottom: 0.5rem;
}

.c-tease__meta {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.c-testimonial {
  padding: 4.5rem 0;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  font-style: normal;
  color: #150e63;
}

@media (min-width: 768px) {
  .c-testimonial {
    padding: 4.5rem;
  }
}

.c-testimonial__icon {
  display: block;
  width: 4.1rem;
  height: 3rem;
  margin: 0 auto 3.5rem;
  color: #0d65d8;
}

.c-testimonial__by {
  display: grid;
  margin: 3rem auto 0;
  padding: 0 2rem;
  grid-template-areas: 'a b' 'a c';
  align-items: center;
  column-gap: 4rem;
  text-align: left;
  width: fit-content;
}

.c-testimonial__photo {
  flex: 0 0 auto;
  height: 6rem;
  border-radius: 50%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  grid-area: a;
}

.c-testimonial__name {
  font-size: 1.8rem;
  grid-area: b;
}

.c-testimonial__position {
  font-size: 1.6rem;
  color: #67768b;
  font-weight: normal;
  grid-area: c;
}

@media (min-width: 768px) {
  .u-adjust-width---5 {
    margin-right: 30%;
  }
}

@media (min-width: 768px) {
  .u-adjust-width---4 {
    margin-right: 24%;
  }
}

@media (min-width: 768px) {
  .u-adjust-width---3 {
    margin-right: 18%;
  }
}

@media (min-width: 768px) {
  .u-adjust-width---2 {
    margin-right: 12%;
  }
}

@media (min-width: 768px) {
  .u-adjust-width---1 {
    margin-right: 6%;
  }
}

@media (min-width: 1024px) {
  .u-adjust-width--1 {
    margin-right: -6%;
  }
}

@media (min-width: 1024px) {
  .u-adjust-width--2 {
    margin-right: -12%;
  }
}

@media (min-width: 1024px) {
  .u-adjust-width--3 {
    margin-right: -18%;
  }
}

@media (min-width: 1024px) {
  .u-adjust-width--4 {
    margin-right: -24%;
  }
}

@media (min-width: 1024px) {
  .u-adjust-width--5 {
    margin-right: -30%;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
  height: 1px;
}

.u-color--section-accent {
  color: var(--section-accent-color);
}

.u-color--headings {
  color: #150e63;
}

.u-color--primary {
  color: #0d65d8;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}
