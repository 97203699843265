.c-how-it-works {
  @include adjust-width(-5);
  @include bp(large) {
    margin: 0 0 0 12%;
  }

  .o-tag {
    @include bp(large) {
      text-align: center;
    }
  }
}
