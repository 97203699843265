.c-arrow-decor {
  display: block;
  margin: -3rem 0 3rem auto;
  height: 6.2rem;
  width: 7.2rem;
  fill: var(--section-accent-color);
  transform-origin: center;
  transform: rotate(60deg);

  @include bp(large) {
    margin-top: 0;
    margin-right: auto;
    transform: none;
  }
}
