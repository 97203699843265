.o-section {
  --section-on-accent-color: #{$color-white};
  --section-accent-color: #{$color-secondary};
  --section-accent-color-transparent: #{rgba($color-secondary, 0.2)};
  --section-text-color: #{$color-text};
  --section-headings-color: #{$color-headings};

  position: relative;
  padding: 4.5rem 0;
  overflow: hidden;

  @include bp(large) {
    padding: 6.5rem 0;
  }

  @include bp(xlarge) {
    padding: 8.5rem 0;
  }

  &--primary {
    --section-accent-color: #{$color-primary};
    --section-accent-color-transparent: #{rgba($color-primary, 0.2)};
  }

  &--tertiary {
    --section-accent-color: #{$color-tertiary};
    --section-accent-color-transparent: #{rgba($color-tertiary, 0.2)};
  }

  @each $variant in blue-black, shifted-blue-black {
    &--#{$variant} {
      --section-on-accent-color: #{$color-blue-black};
      --section-text-color: #{$color-light-grey};
      --section-headings-color: #{$color-white};

      margin: 4.5rem 0;
      background: $color-blue-black;

      @include bp(large) {
        margin: 6.5rem 0;
      }

      @include bp(xlarge) {
        margin: 8.5rem 0;
      }
    }
  }

  &--blue-black {
    --section-accent-color: #{$color-secondary};
    --section-accent-color-transparent: #{rgba($color-secondary, 0.2)};
  }

  &--shifted-blue-black {
    --section-accent-color: #{$color-tertiary};
    --section-accent-color-transparent: #{rgba($color-tertiary, 0.2)};

    @include bp(large) {
      background: transparent;
    }

    &::before {
      @include bp(large) {
        content: '';
        position: absolute;
        top: 3rem;
        bottom: 0;
        right: 0;
        width: calc(100% - 10rem);
        background: $color-blue-black;
      }

      @include bp(xlarge) {
        top: 5rem;
        width: calc(50% + 49rem);
      }
    }
  }
}

.o-section__pulled-up {
  @include bp(large) {
    margin-top: -6.5rem;
  }

  @include bp(xlarge) {
    margin-top: -8.5rem;
  }
}
