.c-skills {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none;
  column-gap: 6rem;
  row-gap: 3.5rem;

  @include bp(medium) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5rem;
  }

  @include bp(large) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp(xlarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.c-skills__item {
  padding: 3.5rem 0 0;
  grid-column-end: span 1;
  grid-row-end: span 1;

  @include bp(medium) {
    padding-top: 7rem;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    margin-top: -3.5rem;
    border-top: 1px solid $color-light-grey;

    @include bp(medium) {
      margin-top: -7rem;
      display: none;
    }
  }

  &:first-child::before {
    display: none;
  }

  &:nth-child(2n + 3)::before {
    @include bp(medium) {
      display: block;
    }

    @include bp(large) {
      display: none;
    }
  }

  &:nth-child(3n + 4)::before {
    @include bp(large) {
      display: block;
    }

    @include bp(xlarge) {
      display: none;
    }
  }

  &:nth-child(4n + 5)::before {
    @include bp(xlarge) {
      display: block;
    }
  }
}

.c-skills__term {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 8.5rem;
  margin-bottom: 3.5rem;
  font-size: 2.4rem;
  font-weight: 500;
  color: $color-headings;
}

.c-skills__logo {
  display: block;
  flex: 1 1 auto;
  max-width: 75%;
  height: 100%;
  filter: grayscale(100%);
}

.c-skills__definition {
  strong {
    color: $color-headings;
  }
}
