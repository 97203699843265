.c-testimonial {
  padding: 4.5rem 0;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  font-style: normal;
  color: $color-headings;

  @include bp(medium) {
    padding: 4.5rem;
  }
}

.c-testimonial__icon {
  display: block;
  width: 4.1rem;
  height: 3rem;
  margin: 0 auto 3.5rem;
  color: $color-primary;
}

.c-testimonial__by {
  display: grid;
  margin: 3rem auto 0;
  padding: 0 2rem;
  grid-template-areas: 'a b' 'a c';
  align-items: center;
  column-gap: 4rem;
  text-align: left;
  width: fit-content;
}

.c-testimonial__photo {
  flex: 0 0 auto;
  height: 6rem;
  border-radius: 50%;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  grid-area: a;
}

.c-testimonial__name {
  font-size: $font-size-text;
  grid-area: b;
}

.c-testimonial__position {
  font-size: $font-size-smaller;
  color: $color-text;
  font-weight: normal;
  grid-area: c;
}
