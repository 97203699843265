.c-related-posts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @include bp(medium) {
    margin-top: 4.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(large) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.c-related-posts__item {
  margin-bottom: 0;
}

.c-related-posts__link {
  text-decoration: none;
  font-weight: normal;

  &:hover {
    text-decoration: none;
  }

  .c-media {
    margin-bottom: 4.5rem;
  }

  .o-tag {
    margin-bottom: 2rem;
  }
}
