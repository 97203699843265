@mixin adjust-width($n: -1) {
  @if ($n < 0) {
    @include bp(medium) {
      margin-right: $n * -6%;
    }
  }
  @else if ($n > 0) {
    @include bp(large) {
      margin-right: $n * -6%;
    }
  }
}
