/* ==========================================================================
   #ALIGN
   ========================================================================== */

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}
