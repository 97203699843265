.c-logo {
  display: block;
  width: 14.7rem;
  height: 7.2rem;
}

.c-logo__icon {
  display: block;
  width: 100%;
  height: 100%;
}
