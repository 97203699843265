.c-arrow-list {
  @include custom-list;
}

.c-arrow-list__item {
  display: flex;
  align-items: flex-start;
}

.c-arrow-list__bullet {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  margin: -0.2rem 1.3rem -0.1rem 0;
  stroke: var(--section-accent-color);
}
